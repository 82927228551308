import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { createGlobalStyle } from 'styled-components'
import { TopMenu } from '../topMenu/TopMenu'
import { Footer } from '../footer/Footer'
import { darkenLighten } from '../../utils/ColorAdjust'
import styled from 'styled-components'

const defaultTheme = {
  pageBgColor: '#edd1d4',
  pageColor: '#426356',
  pageLinkColor: '#69b061',
  headerColor: '#D4B3b7',
  headerBgColor: '#679c87',
  footerColor: '#D4B3b7',
  footerBgColor: '#679c87',
}

// Diese Funktion nimmt einen Farbnamen als Argument entgegen.
// Sie erstellt den SVG-Code als String und ersetzt den Platzhalter für die Farbe durch den übergebenen Wert.
// encodeURIComponent(svgCode) kodiert den SVG-Code, damit er in einer Daten-URL verwendet werden kann.
// Die Funktion gibt die vollständige Daten-URL zurück.
function createSvgUrl(color) {
  const svgCode = `<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='${color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>`
  const encodedSvg = encodeURIComponent(svgCode)
  const dataUrl = `data:image/svg+xml;charset=utf8,${encodedSvg}`
  return dataUrl
}

// define Color of SVG hampurger menu-togglers in two different stages
const initialColor = 'chocolate'
const initialSvgUrl = createSvgUrl(initialColor)

const scrolledColor = 'palegoldenrod'
const scrolledSvgUrl = createSvgUrl(scrolledColor)

console.log(initialSvgUrl)

const GlobalStyle = createGlobalStyle`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap
html {
  --page-color: ${(props) => props.pageColor};
  --page-color-dark: ${(props) => props.pageColorDark};
  --page-color-darker: ${(props) => props.pageColorDarker};
  --page-bg-color: ${(props) => props.pageBgColor};
  --page-bg-color-dark: ${(props) => props.pageBgColorDark};
  --page-bg-color-darker: ${(props) => props.pageBgColorDarker};
  --page-link-color: ${(props) => props.pageLinkColor};
  --page-link-color-darker: ${(props) => props.pageLinkColorDarker};
  --header-color: ${(props) => props.headerColor};
  --header-color-lighter: ${(props) => props.headerColorLighter};
  --header-bg-color: ${(props) => props.headerBgColor};
  --header-bg-color-darker: ${(props) => props.headerBgColorDarker};
  --footer-color: ${(props) => props.footerColor};
  --footer-hover-color: aliceblue;
  --footer-bg-color: ${(props) => props.footerBgColor};
  --toggler-url-dark: url("${initialSvgUrl}");
  --toggler-url-light: url("${scrolledSvgUrl}");
}

body {
    color: var(--page-color)  ;
    background: var(--page-bg-color);
    height: 100vh !important;
    font-family: ${(props) => props.mainFont};
    /* hyphens: auto; */
  }

  main {
    padding: 0 1rem 0 1rem;
  }
  
  li.language-switcher {
    font-size: 1.4rem;
  }
  
  div.iframe {
    height: 50vh;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.titleFont};
  }

  h1 {
    font-size: 2.6rem !important;
  }
  
  h2 {
    font-size: 2.2rem !important;
  }

  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.4rem;
  }

    h5 {
    font-size: 1.2rem;
  }

  iframe {
    width: 100%;
  }

  p, ul li {
    font-size: 1.2rem !important;
  }

    em {
    font-size: 1.2rem;
  }

  blockquote cite {
    font-size: 1rem !important;
    font-style: normal;
  }

  blockquote p {
    font-size: 1.8rem !important;
    font-style: italic;
    color: var(--page-color-lighter) !important;
  }

  a.nav-link {
    font-family: ${props => props.navFont} !important;
  }

  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
    
  }
  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
    
  }
  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {

      p.block-img {
    display: block;
  }
    
    p, p strong em {
      font-size: 1.2rem !important;
    }
    
    li {
      font-size: 1.6rem !important;
    }
  
  }
  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {

}
`
export const Layout = ({
  children,
  topMenu,
  activeDocMeta,
  websiteDaten,
  ...props
}) => {
  // console.log('layout props', props)
  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      prismicTheme {
        ...ThemeFragment
      }
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  console.log('theme', queryData.prismicTheme.data.fonts)

  const fonts = queryData.prismicTheme.data.fonts
  console.log('fonts', fonts)
  let mainFont = ''
  let titleFont = ''
  let navFont = ''
  let logoFont = ''
  let specFont = ''

  for (let index = 0; index < fonts.length; index++) {
    const element = fonts[index]
    switch (element.font_usage) {
      case 'Hauptschrift':
        mainFont = element.google_font
        break 
      case 'Überschriften':
        titleFont = element.google_font
        break 
      case 'Navigation':
        navFont = element.google_font
        break 
      case 'Logo':
        logoFont = element.google_font
        break 
      case 'Spezial':
        specFont = element.google_font
        break 
      default:
        null
    }
  }

  const themePageBgColor = queryData.prismicTheme.data.page_bg_color
  const themePageColor = queryData.prismicTheme.data.page_color
  const themePageLinkColor = queryData.prismicTheme.data.page_link_color
  const themeHeaderColor = queryData.prismicTheme.data.header_color
  const themeHeaderBgColor = queryData.prismicTheme.data.header_bg_color
  const themeFooterColor = queryData.prismicTheme.data.footer_color
  const themeFooterBgColor = queryData.prismicTheme.data.footer_bg_color

  const pageColorDarker = themePageColor
    ? darkenLighten(themePageColor, 100)
    : darkenLighten(defaultTheme.pageColor, 100)
  const pageColorDark = themePageColor
    ? darkenLighten(themePageColor, 90)
    : darkenLighten(defaultTheme.pageColor, 90)
  const pageBgColorDarker = themePageBgColor
    ? darkenLighten(themePageBgColor, -2)
    : darkenLighten(defaultTheme.pageBgColor, -2)
  const pageBgColorDark = themePageBgColor
    ? darkenLighten(themePageBgColor, -10)
    : darkenLighten(defaultTheme.pageBgColor, -10)
  const pageLinkColorDarker = themePageLinkColor
    ? darkenLighten(themePageLinkColor, -40)
    : darkenLighten(defaultTheme.pageLinkColor, -40)
  const headerColorLighter = themeHeaderColor
    ? darkenLighten(themeHeaderColor, 40)
    : darkenLighten(defaultTheme.headerColor, 40)
  const headerBgColorDarker = themeHeaderBgColor
    ? darkenLighten(themeHeaderBgColor, -10)
    : darkenLighten(defaultTheme.headerBgColor, -10)

  return (
    <>
      <Helmet>
        <html lang="de" />
        {/* Meta Tags */}
        <meta charSet="utf-8" />
        <title>{queryData.site.siteMetadata.title}</title>
        <meta
          name="description"
          content={queryData.site.siteMetadata.description}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900"
          rel="stylesheet"
          type="text/css"
        />

        {/* Prismic preview */}
        <script
          async
          defer
          src="https://static.cdn.prismic.io/prismic.js?new=true&repo=klap-web-site"
        ></script>
        {/* Chat Client */}
        <script
          async
          type="text/javascript"
          src="https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/f0e2f54e174840f19a1b945544d388aa8009cfc1bbd84e4e982ffdd4202cd7cd.js"
        ></script>
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
      </Helmet>
      <GlobalStyle
        pageColor={themePageColor ? themePageColor : defaultTheme.pageColor}
        pageBgColor={
          themePageBgColor ? themePageBgColor : defaultTheme.pageBgColor
        }
        pageLinkColor={
          themePageLinkColor ? themePageLinkColor : defaultTheme.pageLinkColor
        }
        headerColor={
          themeHeaderColor ? themeHeaderColor : defaultTheme.headerColor
        }
        headerBgColor={
          themeHeaderBgColor ? themeHeaderBgColor : defaultTheme.headerBgColor
        }
        footerColor={
          themeFooterColor ? themeFooterColor : defaultTheme.footerColor
        }
        footerBgColor={
          themeFooterBgColor ? themeFooterBgColor : defaultTheme.footerBgColor
        }
        pageColorDarker={pageColorDarker}
        pageColorDark={pageColorDark}
        pageBgColorDark={pageBgColorDark}
        pageBgColorDarker={pageBgColorDarker}
        pageLinkColorDarker={pageLinkColorDarker}
        headerColorLighter={headerColorLighter}
        headerBgColorDarker={headerBgColorDarker}
        mainFont={mainFont}
        titleFont={titleFont}
        navFont={navFont}
        logoFont={logoFont}
        specFont={specFont}
      />
      <TopMenu topMenu={topMenu} activeDocMeta={activeDocMeta} />
      <Container fluid>
        <main>
          <Row>
            <Col>{children}</Col>
          </Row>
        </main>
        <Footer topMenu={topMenu} websiteDaten={websiteDaten} />
      </Container>
    </>
  )
}
